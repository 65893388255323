import React, { ReactChildren, ReactChild } from "react"
import "./blockContainerList.scss"

interface AuxProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[]
}

const BlockContainerList = ({ children }: AuxProps) => {
  return (
    <ul className="list-other-offer px-4 py-3 d-f ai-c bcg-2 br-12 fd-md-c ai-md-fs bc-md-w w-100 jc-sb">
      {children}
    </ul>
  )
}

export default BlockContainerList
