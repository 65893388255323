import React, { useEffect, useState } from "react"
import Utils from "../../utils/utils"
import ImgCustom from "../imgCustom/imgCustom"
import SliderCustom from "../sliderCustom/sliderCustom"
import "./blockMateriels.scss"

const BlockMateriels = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(Utils.isMobileSize())
    })
    setIsMobile(Utils.isMobileSize())
  }, [])
  /*** MOBILE */

  if (isMobile && data) {
    return (
      <div className="slider-materiel">
        <SliderCustom
          settings={{
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
          }}
        >
          {data.map((el, index) => (
            <div key={index} className="d-f fd-c jc-c ai-c py-2 px-3">
              <div className="container-img d-f ai-c jc-c mx-a">
                <ImgCustom src={el.icon.path} alt={el.icon.alt} />
                {/**
                 * 
                <img src={el.icon.path} alt={el.icon.alt}></img>
                 */}
              </div>
              <p className="fs-16 lh-24 ta-c">{el.label}</p>
              <div
                dangerouslySetInnerHTML={{ __html: el.description }}
                className="description-resume-materiel fs-14 ff-gbook lh-24 ta-c"
              ></div>
            </div>
          ))}
        </SliderCustom>
      </div>
    )
  }
  /** DESKTOP */
  return (
    <>
      <ul className="advanced d-f ul">
        {data && (
          <>
            {data
              .filter((e, i) => i < 2)
              .map((el, index) => (
                <li key={index} className="card px-1 li">
                  <div
                    style={{ borderRadius: "8px" }}
                    className="d-f ai-c bcg-2 h-100 p-3"
                  >
                    <div className="img img-icon-materiel d-f j-c ai-c">
                      <ImgCustom src={el.icon.path} alt={el.icon.alt} />
                      {/**
                      <img src={el.icon.path} alt={el.icon.alt}></img>
                       * 
                       */}
                    </div>
                    <div>
                      <h3 className="fs-16 lh-24 ">{el.label}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: el.description }}
                        className="description-resume-materiel fs-14 ff-gbook lh-24"
                      ></div>
                    </div>
                  </div>
                </li>
              ))}
          </>
        )}
      </ul>
      <div className="mt-2">
        <ul className="ul list-materiel d-f">
          {data && (
            <>
              {data
                .filter((e, i) => i >= 2)
                .map((el, index) => (
                  <li key={index} className="li px-1">
                    <div
                      style={{ borderRadius: "8px" }}
                      className="card-materiel h-100 p-r p-2 bcg-2 br-8"
                    >
                      <div className="front d-f h-100 fd-c bcg-2">
                        <div className="img-container ">
                          <div className="img-icon-materiel d-f jc-c ai-c mx-a">
                            <ImgCustom src={el.icon.path} alt={el.icon.alt} />
                            {/**
                      <img src={el.icon.path} alt={el.icon.alt}></img>
                       * 
                       */}
                          </div>
                        </div>
                        <p className=" mb-0 label-materiel fs-16 lh-24 ta-c">
                          {el.label}
                        </p>
                      </div>
                      <div className="back px-2 py-2">
                        <div
                          dangerouslySetInnerHTML={{ __html: el.description }}
                          className="description-resume-materiel fs-14 ff-gbook lh-24 mb-0"
                        ></div>
                      </div>
                    </div>
                  </li>
                ))}
            </>
          )}
        </ul>
      </div>
    </>
  )
}

export default BlockMateriels
